* {
  box-sizing: border-box;
}

@font-face {
  font-family: "Inter-Black";
  src: url(./assets/fonts/Inter-Black.woff) format("opentype")
}

@font-face {
  font-family: "Inter-Bold";
  src: url(./assets/fonts/Inter-Bold.woff) format("opentype")
}

@font-face {
  font-family: "Inter-SemiBold";
  src: url(./assets/fonts/Inter-SemiBold.woff) format("opentype")
}

@font-face {
  font-family: "Inter-Regular";
  src: url(./assets/fonts/Inter-Regular.woff) format("opentype")
}

@font-face {
  font-family: "Inter-Light";
  src: url(./assets/fonts/Inter-Light.woff) format("opentype")
}

html,
body,
#root {
  min-height: 100vh;
  font-family: 'Inter', sans-serif;
  background-color: #090E15;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-position: center 5.25rem;
  background-repeat: no-repeat;
  color: white;
  overflow-x: hidden;
  --rt-opacity: 1;
  --wcm-z-index: 300;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

p,
figure {
  margin: 0;
}

.Toastify__toast-container {
  max-width: 27.625rem;
  width: fit-content;
  min-width: 20rem;
  top: 1em;
  left: 50%;
  transform: translateX(-50%);
}

div.grecaptcha-badge {
  z-index: 1;
}